@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-input {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;

	&__item {
		display: flex;
		justify-content: space-between;
	}

	&__label {
		display: flex;
		flex-direction: column;
		gap: 4px;

		&--note {
			color: var(--color-gray-600, $grey-dark);
		}
	}

	&__birdthdates {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__birdthdate {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 7px;

		.control-group__label {
			top: 18px;
		}

		.control-group__input {
			height: 56px;
			border: 1px solid $grey-light;
		}

		.control-group--touched,
		&--has-value {
			.control-group__label {
				top: 9px;
				left: 6px;
			}

			.control-group__input {
				padding-top: 12px;
			}
		}
	}

	&__child-index {
		@extend %text-bold;

		font-size: 1rem;
		color: $color-primary;
	}
}
