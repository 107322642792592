@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.calendar-disabled-view {
	background-color: white;
	text-align: center;
	align-items: center;
	position: absolute;
	top: 60px;
	bottom: 70px;
	display: grid;
	width: 100%;
	border-radius: $border-radius-big;

	@include gridle_state(md) {
		background-color: rgba(255, 255, 255, 0.7);
		position: absolute;
		top: 0;
		left: 0;
		min-height: 100%;
		display: flex;
		right: 0;
		justify-content: center;

	}

	&__title {
		@extend %font-bold;

		font-size: 1.8rem;

		@include gridle_state(md) {
			font-size: 1.6rem;
		}
	}

	&__content {
		padding: 20px;

		@include gridle_state(md) {
			padding: 50px;
		}
	}

	&__description {
		margin-top: 10px;
	}

	&__cta {
		display: block;
		margin-top: 20px;

		@include gridle_state(md) {
			display: none;
		}
	}
}
