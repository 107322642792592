@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-height: 60px;
$dates-selected-height: 56px;
$footer-height: 70px;

.departure-date-side-panel-content {
	height: 100%;
	position: relative;
	background: white;

	.smart-dp-header {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
	}

	&__header {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

		&-title {
			color: $header-text-color;
			background-color: $header-background-color;
			height: $header-height;
			display: flex;
			justify-content: space-between;
			padding: 17px 16px;
			box-sizing: border-box;
			border-bottom: 1px solid $grey-light;
			box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
		}

		&-icon {
			height: 24px;
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0 auto;
		padding-top: 40px;
	}

	&__body {
		height: 100vh;
		background: white;
		overflow: hidden;
		box-sizing: border-box;
		padding-top: $header-height + $dates-selected-height;
	}

	&__footer {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: $footer-height;
		padding: 10px 20px;
		box-sizing: border-box;
		background: transparent;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: white;

		&--bordered {
			box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
			border-top: 1px solid $grey-medium;
		}
	}

	&__note {
		width: 60%;
		font-size: 1.1rem;

		.amount {
			font-size: 2.2rem;

			&__suffix {
				font-size: 1.2rem;
			}
		}
	}

	&__small-note {
		color: $grey-dark;
		font-size: 0.75rem;
	}

	&__cta {
		width: 40%;

		.button {
			height: 100%;
			min-height: 40px;
		}
	}

	&__dates-selected {
		display: flex;
		align-items: center;
		height: $dates-selected-height;
		box-sizing: border-box;
	}

	&__date-selected {
		$this: &;

		align-items: center;
		justify-content: space-between;
		display: flex;
		position: relative;
		padding: 18px 16px;
		flex: 1 1 50%;
		transition: all 0.2s ease-in-out;

		&:first-of-type::after {
			content: "";
			position: absolute;
			right: 0;
			top: 7px;
			width: 1px;
			height: calc(100% - 14px);
			background-color: $grey-light;
		}

		&-clear-icon {
			color: #ccc;
			height: 24px;
			width: 24px;
		}

		&-value,
		&-label {
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 400;
			transition: all 0.2s ease-in-out;
		}

		&--has-value {
			#{$this}-label {
				@extend %font-bold;

				margin-bottom: 4px;
				color: var(--input-label-active-color, $color-primary);
				font-size: 1rem;
				line-height: 1.3rem;
			}
		}

		&--disabled {
			color: $grey-medium;
		}
	}

	.date-picker-calendar {
		.week-days {
			padding-top: 19px;
			padding-bottom: 19px;
			border-color: $grey-light;
			box-sizing: content-box;
		}
	}
}
