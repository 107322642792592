@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-fieldset {
	background: #fff;
	border-top-left-radius: $border-radius-big;
	border-top-right-radius: $border-radius-big;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 17px 16px;
		border-bottom: 1px solid $grey-light;
	}

	&__title {
		@extend %font-bold;

		font-size: 2rem;

		@include gridle_state(md) {
			font-size: 1.8rem;
			line-height: 2.2rem;
		}
	}

	&__remove-button {
		@extend %link;

		display: flex;
		align-items: center;
		font-size: 1.2rem;
		text-decoration: underline;
		cursor: pointer;

		svg {
			margin-right: 9.5px;
		}
	}

	&__content {
		padding: 8px 16px 17px;
	}

	&__warning {
		padding: 8px 16px;
		margin-bottom: 16px;

		.alert-message__text {
			font-size: 1.2rem;
		}
	}

	& .alert-message--error {
		margin-bottom: 0;
	}
}
